import React, {useEffect} from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import DiagnosisRootComponent from './feature/diagnosis/create/components/DiagnosisRootComponent';
import MainNavigation from './common/presentation/navigation/MainNavigation';
import {JoinDiagnosisChallengeComponent} from './feature/diagnosis/join/JoinDiagnosisChallengeComponent';
import {DiagnosisChallengeComponent} from './feature/diagnosis/challenge/DiagnosisChallengeComponent';
import ScrollToTop from './common/ui/scroll/ScrollToTop';
import {initializeRemoteConfig} from "./common/RemoteConfigHook";
import {CookiesProvider} from "react-cookie";
import {AboutRootComponent} from "./feature/about/AboutRootComponent";
import {AdRefreshProvider} from "./common/ui/ad/AdRefreshContext";
import {MarkoComponent} from "./feature/marko/MarkoComponent";

function App() {
    useEffect(() => {
        initializeRemoteConfig()
    }, []);

    return (
        <CookiesProvider>
            <AdRefreshProvider>
                <Router>
                    <ScrollToTop/>
                    <MainNavigation/>
                    <main>
                        <Routes>
                            <Route path="/" element={<DiagnosisRootComponent/>}/>
                            <Route path="/about" element={<AboutRootComponent/>}/>
                            <Route path="/diagnosis-challenge/:id" element={<DiagnosisChallengeComponent/>}/>
                            <Route path="/diagnosis-challenge/join" element={<JoinDiagnosisChallengeComponent/>}/>
                            <Route path="/marko" element={<MarkoComponent />} />
                            <Route path="*" element={<Navigate to="/" replace/>}/>
                        </Routes>
                    </main>
                </Router>
            </AdRefreshProvider>
        </CookiesProvider>
    );
}

export default App;
